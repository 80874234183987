import _ from 'lodash';
import React from 'react';
import { useForm } from 'react-hook-form';
import { ethers } from 'ethers';
import * as thetajs from '@thetalabs/theta-js';
import FormField from '../../components/FormField';
import {
    formatNativeTokenAmountToLargestUnit,
    isHolderSummary,
    isValidAmount,
    numberWithCommas, toNativeTokenLargestUnit
} from '../../utils/Utils';
import {TDropAsset, TFuelAsset, ThetaAsset} from '../../constants/assets';
import {getMaxDelegatedStakeAmount, getMaxStakeAmount, getMinStakeAmount, StakePurposeForTDROP} from '../../constants';
import FlatButton from "../buttons/FlatButton";
import BigNumber from "bignumber.js";

export default function DepositStakeTxForm(props) {
    const {onSubmit, defaultValues, formRef, selectedAccount, assets, chainId, onShowDelegatedGuardianNodes} = props;
    const {register, handleSubmit, errors, watch, setValue} = useForm({
        mode: 'onChange',
        defaultValues: defaultValues || {
            purpose: thetajs.constants.StakePurpose.StakeForGuardian,
            holder: '',
            holderSummary: '',
            amount: '1000',
            delegatedGuardianNode: null
        }
    });
    React.useEffect(() => {
        register('delegatedGuardianNode');
    }, [register]);
    const purpose = parseInt(watch('purpose'));
    const delegatedGuardianNode = watch('delegatedGuardianNode');
    let holderTitle = null;
    let holderPlaceholder = null;
    let stakeAmountTitle = null;

    if (purpose === thetajs.constants.StakePurpose.StakeForValidator) {
        holderTitle = 'AI Validator Safe Node Holder (Address)';
        holderPlaceholder = 'Enter validator node address';
        //stakeAmountTitle = 'Theta Stake Amount';
        stakeAmountTitle = 'Note to the Stakers';
    } else if (purpose === thetajs.constants.StakePurpose.StakeForGuardian) {
        holderTitle = 'AI Guardian Safe Node Holder (Summary)';
        holderPlaceholder = 'Enter guardian node summary';
        //stakeAmountTitle = 'Theta Stake Amount';
        stakeAmountTitle = 'Note to the Stakers';
    } else if (purpose === thetajs.constants.StakePurpose.StakeForEliteEdge) {
        holderTitle = 'AI Safe Node Holder (Summary)';
        holderPlaceholder = 'Enter edge node summary';
        //stakeAmountTitle = 'TFuel Stake Amount';
        stakeAmountTitle = 'Note to the Stakers';
    } else if (purpose === StakePurposeForTDROP) {
        holderTitle = null;
        holderPlaceholder = null;
        //stakeAmountTitle = 'TDrop Stake Amount';
        stakeAmountTitle = 'Note to the Stakers';
    }
    const populateMaxAmount = () => {
        let amount = '';
        let max = getMaxStakeAmount(purpose);
        if(purpose === thetajs.constants.StakePurpose.StakeForValidator || purpose === thetajs.constants.StakePurpose.StakeForGuardian){
            amount = toNativeTokenLargestUnit(selectedAccount.balances['thetawei']).toString(10);

            if (purpose === thetajs.constants.StakePurpose.StakeForEliteEdge) {
                amount = Math.min(max, parseFloat(amount));
            } else if (
                purpose === thetajs.constants.StakePurpose.StakeForGuardian ||
                !_.isNil(delegatedGuardianNode)) {
                max = getMaxDelegatedStakeAmount(purpose);
                amount = Math.min(max, parseFloat(amount));
            }
        }
        else if(purpose === thetajs.constants.StakePurpose.StakeForEliteEdge){
            const maxTfuelBN = (new BigNumber(selectedAccount.balances['tfuelwei'])).minus(thetajs.constants.gasPriceDefault);
            amount = toNativeTokenLargestUnit(maxTfuelBN.toString(10)).toString(10);

            amount = Math.min(max, parseFloat(amount));
        }
        else if(purpose === StakePurposeForTDROP){
            const tDropAsset = TDropAsset(chainId);
            const balance = selectedAccount.balances[tDropAsset.address] || '0';
            amount = toNativeTokenLargestUnit(balance).toString(10);
        }
        setValue('amount', amount);
    }

    return (
        <form className={'TxForm TxForm--DepositStake'}
              onSubmit={handleSubmit(onSubmit)}
              ref={formRef}
        >
            <FormField title={'Stake Type'}
                       error={errors.purpose && 'Stake type is required'}
            >
                <select
                    className={'RoundedInput'}
                    name={'purpose'}
                    ref={register({required: true})}
                >
                    <option key={'__placeholder__'}
                            value={''}
                            disabled>
                        Select stake type
                    </option>
                    <option key={'guardian'}
                            value={thetajs.constants.StakePurpose.StakeForGuardian}>
                        Guardian Node (THETA)
                    </option>
                    <option key={'validator'}
                            value={thetajs.constants.StakePurpose.StakeForValidator}>
                        Validator Node (THETA)
                    </option>
                    <option key={'elite-edge'}
                            value={thetajs.constants.StakePurpose.StakeForEliteEdge}>
                        Edge Node (TFUEL)
                    </option>
                    {
                        TDropAsset(chainId) &&
                        <option key={'tdrop'}
                                value={StakePurposeForTDROP}>
                            TDROP
                        </option>
                    }
                </select>
            </FormField>

            {
                (purpose === thetajs.constants.StakePurpose.StakeForValidator) &&
                <FormField title={holderTitle}
                           error={errors.holder && 'A valid validator address is required'}
                >
                    <input name="holder"
                           className={'RoundedInput'}
                           //placeholder={holderPlaceholder}
                           // From https://medium.com/theta-network/bridgetower-capital-launches-enterprise-validator-node-on-the-theta-network-59168e3e85e1
                           value="0xfAE4EfAD7fcc8e3d76dc53Ee92c91d88FB7388Aa"
                           ref={register({
                               required: true,
                               validate: (s) => { return true; }
                               //validate: (s) => ethers.utils.isAddress(s)
                           })}
                           readonly/>
                </FormField>
            }

            {
                (purpose === thetajs.constants.StakePurpose.StakeForGuardian) &&
                <FormField title={(delegatedGuardianNode ? 'Delegated Guardian Node' : holderTitle)}
                           error={errors.holderSummary && 'Guardian node summary or delegated guardian node is required'}
                >
                    <React.Fragment>
                        <textarea name="holderSummary"
                          className={'RoundedInput'}
                          style={{height: 100, display: (delegatedGuardianNode ? 'none' : 'block')}}
                          //placeholder={holderPlaceholder}
                          // From https://medium.com/gpool/stake-theta-to-gpools-guardian-node-using-theta-web-wallet-5ea06d14138
                          value="0x88884a84D980bbfB7588888126fb903486Bb8888ad5081ea3b105525ab3592a5e8e26c4b36478bd29afe42e7d568e6f03b6cf0004d2def129f1fd2c06d40410b2c7ae5faa7b487051447b6a599555b9e5ec765e172ea7be720553b4061e70e61811539e017b6f3990a32ca7804dd303370b5b0fb12efb840a3ed8e6e57411f1985bb2501bd900b05b43d337bb4ce193043fce207cd82b1cc70cf7dcfcdab521cde481e993dff4456dc7aa96a37e93ae92806431c31384d73095b7987b1817d4f3ac2f06a4dbf91f43a3c153624b9f3eb2b8941c4b7cf617af8d67f1f539e217dd9fbcb7e01"
                          ref={register({
                              required: true,
                              validate: (s) => { return true; }
                              //validate: (s) => isHolderSummary(s)
                          })}
                          readonly/>
                        {
                            /* _.isNil(delegatedGuardianNode) */ false &&
                            <a onClick={() => {
                                onShowDelegatedGuardianNodes((node) => {
                                    setValue('holderSummary', node.node_summary);
                                    setValue('delegatedGuardianNode', node);
                                });
                            }}
                               className={'Link'}
                               style={{marginTop: 8, textAlign: 'left'}}
                            >
                                Select Delegated Guardian Node
                            </a>
                        }
                    </React.Fragment>
                    {
                        !_.isNil(delegatedGuardianNode) &&
                        <React.Fragment>
                            <div className={'RoundedInput'}>
                                <div className={'RoundedInputClearableValue'}>
                                    <div>{delegatedGuardianNode.name}</div>
                                    <a onClick={() => {
                                        setValue('holderSummary', null);
                                        setValue('delegatedGuardianNode', null);
                                    }}
                                       style={{marginLeft: 'auto'}}
                                    >
                                        <img src={'/img/icons/alert-x@2x.png'}/>
                                    </a>
                                </div>
                            </div>
                        </React.Fragment>
                    }
                </FormField>
            }


            {
                (purpose === thetajs.constants.StakePurpose.StakeForEliteEdge) &&
                <FormField title={holderTitle}
                           error={errors.holderSummary && 'Edge node summary is required'}
                >
          <textarea name="holderSummary"
                    className={'RoundedInput'}
                    style={{height: 100}}
                    //placeholder={holderPlaceholder}
                    // From https://thetanext.io/staking/tfuel
                    value="0xeee4E2bB1fd8Fab1551eC616078Ea5b640A13474806146c37c7c6584af2e7eaaf2b7c674ca14e8cb118385cfcfe5f60140acb6592cb8009c2661c3f6413a9dff315d4bc083ddaaa28c2d157e36b75474d8ed7f7a9b3697a61eaa6af349beffb99850f1fb731fadb1721fad4f25707d75f896ebff1032ae34ff88493999363f9152ae529b6efc9ab23fef042189166e0ab1acff9514eafefe14209571143dab8695e5f07a660035fc6f1434409fc47a9ceea7895393d228cb5bf6355bf7e5c116404e6a2d6addaf5131f06a3f15e781b0a4a48f43975c1a929e2685920a79e58cac4beb65004de1114b9d9d002b9da15e7c9eb96d05c9eb160085219f0658ab05160df7df20"
                    ref={register({
                        required: true,
                        validate: (s) => { return true; }
                        //validate: (s) => thetajs.transactions.DepositStakeV2Transaction.isValidHolderSummary(purpose, s)
                    })}
                    readonly/>
                </FormField>
            }

            {<input name="amount"
                    value="1000"
                    ref={register({
                        required: true,
                        validate: (s) => { return true; }
                    })}
                    hidden/>
            }

            <FormField title={stakeAmountTitle}>
                <div className={'RoundedInputWrapper'}>
                    <p className={'RoundedInput'}>
                        <b>Important:</b> Please note that the staking process is irreversible. Once you stake your tokens, you will not be able to withdraw them until the staking period is over. Please make sure you understand the staking process and the risks involved before proceeding.
                    </p>
                </div>
            </FormField>

          {/*
            <FormField title={stakeAmountTitle}
                       error={errors.amount && errors.amount.message}
            >
                <div className={'RoundedInputWrapper'}>
                    <input name="amount"
                       className={'RoundedInput'}
                       placeholder={'Enter amount to stake'}
                       ref={register({
                           required: {
                               value: true,
                               message: 'Stake amount is required'
                           },
                           validate: {
                               sufficientBalance: (s) => {
                                   let isValid = true;
                                   if (purpose === thetajs.constants.StakePurpose.StakeForEliteEdge) {
                                       isValid = isValidAmount(selectedAccount, TFuelAsset, s);
                                   } else if (
                                       purpose === thetajs.constants.StakePurpose.StakeForGuardian ||
                                       purpose === thetajs.constants.StakePurpose.StakeForValidator) {
                                       isValid = isValidAmount(selectedAccount, ThetaAsset, s);
                                   } else if (purpose === StakePurposeForTDROP) {
                                       const tDropAsset = TDropAsset(chainId);

                                       isValid = isValidAmount(selectedAccount, tDropAsset, s);
                                   }

                                   return isValid ? true : 'Insufficient balance';
                               },
                               moreThanMin: (s) => {
                                   const f = parseFloat(s);
                                   const min = getMinStakeAmount(purpose);
                                   if (purpose === thetajs.constants.StakePurpose.StakeForEliteEdge) {
                                       if(min > f){
                                           return `Invalid amount. Must be at least ${numberWithCommas(min)} TFUEL`;
                                       }
                                   } else if (
                                       purpose === thetajs.constants.StakePurpose.StakeForGuardian ||
                                       purpose === thetajs.constants.StakePurpose.StakeForValidator) {
                                       if(min > f){
                                           return `Invalid amount. Must be at least ${numberWithCommas(min)} THETA`;
                                       }
                                   }
                                   return true;
                               },
                               lessThanMax: (s) => {
                                   const f = parseFloat(s);
                                   let max = getMaxStakeAmount(purpose);
                                   if (purpose === thetajs.constants.StakePurpose.StakeForEliteEdge) {
                                       if(max < f){
                                           return `Invalid amount. Must be less than ${numberWithCommas(max)} TFUEL`;
                                       }
                                   } else if (
                                       purpose === thetajs.constants.StakePurpose.StakeForGuardian ||
                                       !_.isNil(delegatedGuardianNode)) {
                                       max = getMaxDelegatedStakeAmount(purpose);
                                       if(max < f){
                                           return `Invalid amount. There's a max of ${numberWithCommas(max)} THETA for delegated nodes. Please download and run your own Guardian Node to stake more.`;
                                       }
                                   }
                                   return true;
                               },
                               moreThanZero: (s) => {
                                   const f = parseFloat(s);

                                   return (f > 0) ? true : 'Invalid stake amount';
                               }
                           }
                       })}/>
                       <FlatButton title={'Max'}
                                   size={'small'}
                                   className={'RoundedInputButton'}
                                   onClick={populateMaxAmount}/>
                </div>
            </FormField>
          */}
        </form>
    );
}
