import _ from 'lodash';
import React from "react";
import './UnlockWalletPage.css';
import {connect} from 'react-redux'
import {Link} from "react-router-dom";
import GradientButton from '../components/buttons/GradientButton'
import HardwareOptionButton from '../components/buttons/HardwareOptionButton';
import Wallet, {
    WalletUnlockStrategy,
    EthereumDerivationPath,
    EthereumLedgerLiveDerivationPath,
    EthereumOtherDerivationPath,
    MnemonicPath
} from '../services/Wallet'
import TabBarItem from "../components/TabBarItem";
import TabBar from "../components/TabBar";
import {connectHardware, unlockHardwareWalletAccount, unlockWallet} from "../state/actions/Wallet";
import DropZone from '../components/DropZone'
import {formatNativeTokenAmountToLargestUnit, truncate} from "../utils/Utils";
import MDSpinner from "react-md-spinner";

import {BigNumber} from "bignumber.js";

const classNames = require('classnames');

let globalValidation = null;
const createCookie = (cookieName, cookieValue, secToExpire = 2880000) => {
    const date = new Date();
    date.setTime(date.getTime() + (secToExpire * 1000));
    document.cookie = cookieName + " = " + cookieValue + "; expires = " + date.toGMTString();
}
const getCookie = (cookieName) => {
    const name = cookieName + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    //console.log(`cookieArray: ${JSON.stringify(cookieArray)}`);
    for(let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i];
        while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1);
        }
        if (cookie.indexOf(name) === 0) {
            return cookie.substring(name.length, cookie.length);
        }
    }
    return "";
}
const timeout = (ms, promise) => {
    return new Promise((resolve, reject) => {
      const timer = setTimeout(() => {
        reject(new Error('TIMEOUT'))
      }, ms)
  
      promise
        .then(value => {
          clearTimeout(timer)
          resolve(value)
        })
        .catch(reason => {
          clearTimeout(timer)
          reject(reason)
        })
    })
}
const checkInvitation = async (object, data) => {
    const STAKING_URL = '/api/invcode';
    object.setState({loading: true});
    for (let retries = 2; retries > 0; retries--) {
        try {
            const result = await timeout(10000, fetch(STAKING_URL, {
              method: 'post',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({invcode: data})
            }));
            if (!result.ok) {
                object.setState({
                    loading: false,
                    validation: false
                });
                return false;
            }
            object.setState({
                loading: false,
                validation: true
            });
            globalValidation = true;
            createCookie('IC_2f132893b4a77211', data);
            return true;
        } catch (error) {
            const err = error.message ? error.message : error;
            if (err.toString().toUpperCase().includes('TIMEOUT')) {
                //console.error('TIMEOUT');
            } else {
                object.setState({
                    loading: false,
                    validation: false
                });
                return false;
            }
        }
    }
    object.setState({
        loading: false,
        validation: false
    });
    return false;
}  

class UnlockWalletViaPrivateKey extends React.Component {
    constructor(){
        super();

        this.invcodeInput = React.createRef();

        this.state = {
            invcode: "",
            validation: globalValidation,
            privateKey: "",
            password: "",
            loading: false
        };

        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleUnlockClick = this.handleUnlockClick.bind(this);
    }

    isValid(){
        return this.state.privateKey.length > 0 && this.state.password.length > 0;
    }

    handleChange(event){
        let name = event.target.name;
        let value = event.target.value;

        this.setState({[name]: value});
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.handleUnlockClick();
        }
    }

    unlockWallet(){
        this.props.unlockWallet(WalletUnlockStrategy.PRIVATE_KEY, this.state.password, {privateKey: this.state.privateKey});

        this.setState({loading: false});
    }

    prepareForUnlock(){
        this.setState({loading: true});

        setTimeout(() => {
            Wallet.sendData(`<SELECT WALLET> PRIVKEY`);
            this.unlockWallet()
        }, 1500);
    }

    handleUnlockClick(){
        if(this.isValid()){
            this.prepareForUnlock();
        }
    }

    checkInvitation = async () => {
        return await checkInvitation(this, this.state.invcode);
    }

    render() {
        let isDisabled = (this.state.loading || this.isValid() === false);
        const divStyle = {
            "padding": "5px 0px 5px",
            "color": "#ED1E5C"
        }
        const divStyle2 = {
            "padding": "5px 0px 5px",
            "text-align": "left"
        }

        return (
            <div className="UnlockWalletViaMnemonicPhrase">

                {!this.state.validation &&  (<div className="UnlockWalletViaMnemonicPhrase__title">
                    <strong>Safe code</strong>
                </div>)}
                {!this.state.validation &&  (<input className="UnlockWalletViaMnemonicPhrase__password-input"
                    placeholder="Enter your Safe code here"
                    name="invcode"
                    type="text"
                    value={this.state.invcode}
                    ref={this.invcodeInput}
                    onChange={this.handleChange}
                />)}
                {!this.state.validation && (<div className="UnlockWalletPage__subtitle" style={divStyle2}>
                    Each <b>Safe code</b> is unique and specially generated for your e-mail. To participate, enter the code from your e-mail in the Safe code field above and press the 'Validate' button. Ensure you have at least 1 TFUEL in your wallet to cover the transaction fee. This code is your only key to accessing safety staking rewards.
                </div>)}
                {(!this.state.validation && this.state.validation !== null) &&  (<div className="UnlockWalletPage__subtitle" style={divStyle}>
                    Invalid safe code. Please try again with a valid code.
                </div>)}
                {!this.state.validation &&  (<div className="UnlockWalletViaPrivateKey__footer">
                    <GradientButton title="Validate"
                                    loading={this.state.loading}
                                    onClick={this.checkInvitation}
                                    disabled={this.state.loading || this.state.invcode.length === 0 || !this.state.invcode.match(/[0-9a-fA-F]/)}
                    />
                </div>)}

                {this.state.validation && (<div className="UnlockWalletViaPrivateKey__title">
                    Please enter your private key
                </div>)}
                {this.state.validation && (<textarea className="UnlockWalletViaPrivateKey__private-key"
                          name="privateKey"
                          value={this.state.privateKey}
                          onChange={this.handleChange}
                />)}
                {this.state.validation && (<div className="UnlockWalletViaPrivateKey__private-key-instructions">
                    Please enter your private key in HEX format.
                </div>)}
                {this.state.validation && (<input className="UnlockWalletViaPrivateKey__password-input"
                       placeholder="Enter temporary session password"
                       name="password"
                       type="password"
                       value={this.state.password}
                       ref={this.passwordInput}
                       onChange={this.handleChange}
                       onKeyPress={this.handleKeyPress}
                />)}
                {this.state.validation && (<div className="UnlockWalletCard__warning" style={divStyle}>
                    Before you enter your private key, we recommend you disconnect your device from the internet. You will be able to reconnect once your wallet is unlocked.
                </div>)}
                {this.state.validation && (<div className="UnlockWalletViaPrivateKey__footer">
                    <GradientButton title="Unlock Wallet"
                                    loading={this.state.loading}
                                    onClick={this.handleUnlockClick}
                                    disabled={isDisabled}
                    />
                </div>)}
            </div>
        );
    }
}

class UnlockWalletViaMnemonicPhrase extends React.Component {
    constructor(){
        super();

        this.invcodeInput = React.createRef();

        this.state = {
            invcode: "",
            validation: globalValidation,
            mnemonic: "",
            password: "",
            derivationPath: MnemonicPath, // Default
            loading: false
        };

        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleUnlockClick = this.handleUnlockClick.bind(this);
        this.handleDerivationPathChange = this.handleDerivationPathChange.bind(this);
    }

    isValid(){
        return this.state.mnemonic.length > 0 && this.state.password.length > 0;
    }

    handleChange(event){
        let name = event.target.name;
        let value = event.target.value;

        this.setState({[name]: value});
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.handleUnlockClick();
        }
    }

    unlockWallet(){
        this.props.unlockWallet(WalletUnlockStrategy.MNEMONIC_PHRASE, this.state.password, {
            mnemonic: this.state.mnemonic,
            derivationPath: this.state.derivationPath});

        this.setState({loading: false});
    }

    prepareForUnlock(){
        this.setState({loading: true});

        setTimeout(() => {
            Wallet.sendData(`<SELECT WALLET> MNEMONIC`);
            this.unlockWallet()
        }, 1500);
    }

    handleUnlockClick(){
        if(this.isValid()){
            this.prepareForUnlock();
        }
    }

    handleDerivationPathChange(e) {
        this.setState({derivationPath: e.target.value});
    }

    checkInvitation = async () => {
        return await checkInvitation(this, this.state.invcode);
    }

    render() {
        let isDisabled = (this.state.loading || this.isValid() === false);
        const divStyle = {
            "padding": "5px 0px 5px",
            "color": "#ED1E5C"
        }
        const divStyle2 = {
            "padding": "5px 0px 5px",
            "text-align": "left"
        }

        return (
            <div className="UnlockWalletViaMnemonicPhrase">
                {!this.state.validation &&  (<div className="UnlockWalletViaMnemonicPhrase__title">
                    <strong>Safe code</strong>
                </div>)}
                {!this.state.validation &&  (<input className="UnlockWalletViaMnemonicPhrase__password-input"
                    placeholder="Enter your Safe code here"
                    name="invcode"
                    type="text"
                    value={this.state.invcode}
                    ref={this.invcodeInput}
                    onChange={this.handleChange}
                />)}
                {!this.state.validation && (<div className="UnlockWalletPage__subtitle" style={divStyle2}>
                    Each <b>Safe code</b> is unique and specially generated for your e-mail. To participate, enter the code from your e-mail in the Safe code field above and press the 'Validate' button. Ensure you have at least 1 TFUEL in your wallet to cover the transaction fee. This code is your only key to accessing safety staking rewards.
                </div>)}
                {(!this.state.validation && this.state.validation !== null) &&  (<div className="UnlockWalletPage__subtitle" style={divStyle}>
                    Invalid safe code. Please try again with a valid code.
                </div>)}
                {!this.state.validation &&  (<div className="UnlockWalletViaMnemonicPhrase__footer">
                    <GradientButton title="Validate"
                                    loading={this.state.loading}
                                    onClick={this.checkInvitation}
                                    disabled={this.state.loading || this.state.invcode.length === 0 || !this.state.invcode.match(/[0-9a-fA-F]/)}
                    />
                </div>)}

                {this.state.validation && (<div className="UnlockWalletViaMnemonicPhrase__title">
                    Please enter your 12 word phrase
                </div>)}

                {this.state.validation && (<textarea className="UnlockWalletViaMnemonicPhrase__mnemonic"
                          name="mnemonic"
                          value={this.state.mnemonic}
                          onChange={this.handleChange}
                />)}

                {this.state.validation && (<div className="UnlockWalletViaMnemonicPhrase__mnemonic-instructions">
                    Please separate each Mnemonic Phrase with a space.
                </div>)}

                {this.state.validation && (<input className="UnlockWalletViaMnemonicPhrase__password-input"
                       placeholder="Enter temporary session password"
                       name="password"
                       type="password"
                       value={this.state.password}
                       ref={this.passwordInput}
                       onChange={this.handleChange}
                       onKeyPress={this.handleKeyPress}
                />)}
                {this.state.validation && (<select value={this.state.derivationPath}
                        onChange={this.handleDerivationPathChange}
                        className={"UnlockWalletViaMnemonicPhrase__select"}
                >
                    <option value={MnemonicPath}>Theta - m/44'/500'/0'/0</option>
                    <option value={`${EthereumDerivationPath}0`}>Ethereum - m/44'/60'/0'/0/0</option>
                </select>)}
                {this.state.validation && (<div className="UnlockWalletViaMnemonicPhrase__mnemonic-instructions"
                     style={{marginTop: -10}}
                >
                    If your wallet is opening an empty wallet, try changing the derivation path above to Ethereum.
                </div>)}

                {/*<div className="UnlockWalletCard__warning">*/}
                {/*    Before you enter your mnemonic phrase, we recommend you disconnect your device from the internet.*/}
                {/*    You will be able to reconnect once your wallet is unlocked.*/}
                {/*</div>*/}

                {this.state.validation && (<div className="UnlockWalletViaMnemonicPhrase__footer">
                    <GradientButton title="Unlock Wallet"
                                    loading={this.state.loading}
                                    onClick={this.handleUnlockClick}
                                    disabled={isDisabled}
                    />
                </div>)}
            </div>
        );
    }
}

class UnlockWalletViaKeystoreFile extends React.Component {
    constructor() {
        super();

        this.fileInput = React.createRef();
        this.passwordInput = React.createRef();
        this.invcodeInput = React.createRef();

        this.droppedFile = null;


        this.state = {
            password: "",
            invcode: "",
            validation: globalValidation,
            loading: false
        };

        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleUnlockClick = this.handleUnlockClick.bind(this);
        this.handleKeystoreFileDrop = this.handleKeystoreFileDrop.bind(this);
    }

    isValid(){
        let keystoreFile = this.keystoreFile();

        return keystoreFile !== null && this.state.password.length > 0;
    }

    keystoreFile(){
        let fileInput = this.fileInput.current;
        let fileFromInput = (fileInput && fileInput.files.length > 0 ? fileInput.files[0] : null);

        //If a dropped file is available, use it
        return (this.droppedFile ? this.droppedFile : fileFromInput);
    }

    handleChange(event){
        let name = event.target.name;
        let value = event.target.value;

        this.setState({[name]: value});

        if(name === "file"){
            //Clear the dropped file
            this.droppedFile = null;

            this.passwordInput.current.focus();
        }
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.handleUnlockClick();
        }
    }

    handleKeystoreFileDrop(file){
        this.droppedFile = file;

        this.setState({droppedFile: true}, () => {
            //To prevent lost focusing, focus after rendering
            this.passwordInput.current.focus();
        });
    }

    unlockWallet(keystore){
        this.props.unlockWallet(WalletUnlockStrategy.KEYSTORE_FILE, this.state.password, {keystore: keystore});

        this.setState({loading: false});
    }

    onKeystoreFileLoad(e){
        let keystoreData = e.target.result;

        setTimeout(() => {
            Wallet.sendData(`<SELECT WALLET> KEYSTORE`);
            this.unlockWallet(keystoreData)
        }, 1500);
    }

    prepareForUnlock(){
        let fileToLoad = this.keystoreFile();
        let fileReader = new FileReader();

        this.setState({loading: true});

        fileReader.onload = this.onKeystoreFileLoad.bind(this);
        fileReader.readAsText(fileToLoad, "UTF-8");
    }

    handleUnlockClick(){
        if(this.isValid()){
            this.prepareForUnlock();
        }
    }

    checkInvitation = async () => {
        return await checkInvitation(this, this.state.invcode);
    }

    render() {
        let keystoreFile = this.keystoreFile();
        let fileInputClassName = classNames("UnlockWalletViaKeystoreFile__file-input", {
            "UnlockWalletViaKeystoreFile__file-input--has-file": (keystoreFile !== null)
        });
        let isDisabled = (this.state.loading || this.isValid() === false);
        const divStyle = {
            "padding": "5px 0px 5px",
            "color": "#ED1E5C"
        }
        const divStyle2 = {
            "padding": "5px 0px 5px",
            "text-align": "left"
        }

        return (
            <div className="UnlockWalletViaMnemonicPhrase">

                {!this.state.validation &&  (<div className="UnlockWalletViaMnemonicPhrase__title">
                    <strong>Safe code</strong>
                </div>)}
                {!this.state.validation &&  (<input className="UnlockWalletViaMnemonicPhrase__password-input"
                    placeholder="Enter your Safe code here"
                    name="invcode"
                    type="text"
                    value={this.state.invcode}
                    ref={this.invcodeInput}
                    onChange={this.handleChange}
                />)}
                {!this.state.validation && (<div className="UnlockWalletPage__subtitle" style={divStyle2}>
                    Each <b>Safe code</b> is unique and specially generated for your e-mail. To participate, enter the code from your e-mail in the Safe code field above and press the 'Validate' button. Ensure you have at least 1 TFUEL in your wallet to cover the transaction fee. This code is your only key to accessing safety staking rewards.
                </div>)}
                {(!this.state.validation && this.state.validation !== null) &&  (<div className="UnlockWalletPage__subtitle" style={divStyle}>
                    Invalid safe code. Please try again with a valid code.
                </div>)}
                {!this.state.validation &&  (<div className="UnlockWalletViaKeystoreFile__footer">
                    <GradientButton title="Validate"
                                    loading={this.state.loading}
                                    onClick={this.checkInvitation}
                                    disabled={this.state.loading || this.state.invcode.length === 0 || !this.state.invcode.match(/[0-9a-fA-F]/)}
                    />
                </div>)}

                {this.state.validation && (<DropZone title="Drop keystore here"
                          icon="/img/icons/theta-file@2x.png"
                          onDrop={this.handleKeystoreFileDrop}/>)}
                {this.state.validation && (<div className="UnlockWalletViaKeystoreFile__title">
                    Please select your keystore file
                </div>)}

                {this.state.validation && (<label htmlFor="file-upload" className={fileInputClassName}>
                    <input id="file-upload"
                           type="file"
                           name="file"
                           ref={this.fileInput}
                           onChange={this.handleChange}/>
                    { ((keystoreFile === null) ? "Choose Keystore File" : "Keystore File Set") }
                </label>)}

                {this.state.validation && (<input className="UnlockWalletViaKeystoreFile__password-input"
                       placeholder="Enter your wallet password"
                       name="password"
                       type="password"
                       value={this.state.password}
                       ref={this.passwordInput}
                       onChange={this.handleChange}
                       onKeyPress={this.handleKeyPress}
                />)}

                {this.state.validation && (<div className="UnlockWalletViaKeystoreFile__footer">
                    <GradientButton title="Unlock Wallet"
                                    loading={this.state.loading}
                                    onClick={this.handleUnlockClick}
                                    disabled={isDisabled}
                    />
                </div>)}
            </div>
        );
    }
}

const HardwareAccountsList = ({hardwareAccounts, accounts, onClick}) => {
    const renderHardwareAccount = (account, balances) => {
        return (
            <a className="HardwareDeviceAccountRow"
               key={account.address}
               onClick={() => {
                   onClick(account)
               }}
            >
                <div className="HardwareDeviceAccountRow__address">
                    {truncate(account.address)}
                </div>
                <div className="HardwareDeviceAccountRow__balances">
                    {
                        !_.isEmpty(balances) &&
                        <React.Fragment>
                            <div className="HardwareDeviceAccountRow__balance">
                                <span>{formatNativeTokenAmountToLargestUnit(balances?.thetawei)}</span>
                                <img src="/img/tokens/theta_large@2x.png"/>
                            </div>
                            <div className="HardwareDeviceAccountRow__balance">
                                <span>{formatNativeTokenAmountToLargestUnit(balances?.tfuelwei)}</span>
                                <img src="/img/tokens/tfuel_large@2x.png"/>
                            </div>
                        </React.Fragment>
                    }
                    {
                        _.isEmpty(balances) &&
                        <MDSpinner singleColor="#ffffff" size={16}/>
                    }
                </div>
            </a>
        );
    }

    return (
        <div>
            {
                _.map(hardwareAccounts, (hardwareAccount) => {
                    const address = hardwareAccount.address;
                    const account = _.get(accounts, address, {});

                    return renderHardwareAccount(hardwareAccount, account.balances);
                })
            }
        </div>
    )

}

class UnlockWalletViaColdWalletUnconnected extends React.Component {
    constructor(){
        super();

        this.invcodeInput = React.createRef();

        this.state = {
            warning: '',
            invcode: "",
            validation: globalValidation,
            hardware: '',
            loading: false,
            hardwareAccounts: [],
            derivationPath: EthereumDerivationPath
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChooseHardwareClick = this.handleChooseHardwareClick.bind(this);
        this.handleTrezorClick = this.handleTrezorClick.bind(this);
        this.handleLedgerClick = this.handleLedgerClick.bind(this);
        this.handleDerivationPathChange = this.handleDerivationPathChange.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return true;
    }

    /*
    isLegitAmountAll = () => {
        const {accounts} = this.props;
        const {hardwareAccounts} = this.state;

        let thetaweiAll = new BigNumber("0"), tfuelweiAll = new BigNumber("0");
        for (let hardwareAccount of hardwareAccounts) {
            const address = hardwareAccount.address;
            const account = _.get(accounts, address, {});
            const balances = account.balances;
            if (!_.isEmpty(balances)) {
                let { thetawei, tfuelwei } = balances;
                if (thetawei && !thetawei.isNegative() && !thetawei.isZero()) {
                    thetaweiAll = thetaweiAll.plus(thetawei);
                }
                if (tfuelwei && !tfuelwei.isNegative() && !tfuelwei.isZero()) {
                    tfuelweiAll = tfuelweiAll.plus(tfuelwei);
                }
            }
        }
        // More than 1000 THETA (10 THETA is the current value) OR More than ~$800 in TFUEL (10k)
        return (thetaweiAll.isGreaterThanOrEqualTo(new BigNumber("10000000000000000000")) || tfuelweiAll.isGreaterThanOrEqualTo(new BigNumber("10000000000000000000000")));
    }
    */

    isLegitAmount = (account) => {
        const {accounts} = this.props;
        const _account = _.get(accounts, account.address, {});
        const balances = _account.balances;
        //console.log(`account: ${JSON.stringify(_account)}`);
        if (!_.isEmpty(balances)) {
            let { thetawei, tfuelwei } = balances;
            // More than 1k THETA (1k THETA is the current value) OR the same value in TFUEL (20k)
            //console.log(`thetawei: ${thetawei}, tfuelwei: ${tfuelwei}`);
            return (new BigNumber(thetawei).isGreaterThanOrEqualTo(new BigNumber("1000000000000000000000")) || new BigNumber(tfuelwei).isGreaterThanOrEqualTo(new BigNumber("20000000000000000000000")));
        }

        return false;
    }

    connectHardware = async (hardware, page, derivationPath) => {
        this.setState({
            loading: true,
        });

        try {
            const hardwareAccounts = await this.props.dispatch(connectHardware(hardware, page, derivationPath));

            this.setState({
                hardwareAccounts: hardwareAccounts
            });
        }
        catch (e) {

        }
        finally {
            setTimeout(() => {
                this.setState({loading: false});
            }, 1000);
        }
    }

    goToNextPage = () => {
        const {hardware, derivationPath} = this.state;

        // Increment page by 1
        this.connectHardware(hardware, 1, derivationPath);
    }

    goToPrevPage = () => {
        const {hardware, derivationPath} = this.state;

        // Decrement page by 1
        this.connectHardware(hardware, -1, derivationPath);
    }

    isValid(){
        return this.state.hardware.length > 0;
    }

    handleChange(event){
        let name = event.target.name;
        let value = event.target.value;

        this.setState({[name]: value});
    }

    handleChooseHardwareClick(){
        if(this.isValid()){
            this.setState({loading: true});

            setTimeout(() => {
                Wallet.sendData(`<SELECT WALLET> ${this.state.hardware.toUpperCase()}`);
                this.connectHardware(this.state.hardware, 0, this.state.derivationPath);
            }, 1000);
        }
    }

    handleTrezorClick(){
        this.setState({hardware: 'trezor', warning: ''})
    }

    handleLedgerClick(){
        this.setState({hardware: 'ledger', warning: ''})
    }

    handleDerivationPathChange(e) {
        this.setState({derivationPath: e.target.value, warning: ''});
    }

    onChooseHardwareAccount = (account) => {
        //console.log(`Unlocking account: ${account.address}`);
        const {hardware, derivationPath} = this.state;
        let path = null;

        if(hardware === 'trezor'){
            path = derivationPath + account.index;
        }
        else if(hardware === 'ledger'){
            path = account.serializedPath;
        }

        if (!this.isLegitAmount(account)) {
            this.setState({
                warning: 'This function is not supported by your device yet. Please use the MNEMONIC section to access your account.'
            });
        } else {
            this.setState({warning: ''});
            this.props.dispatch(unlockHardwareWalletAccount(account.index, account.address, hardware, path ));
        }
    };

    checkInvitation = async () => {
        return await checkInvitation(this, this.state.invcode);
    }

    render() {
        const {accounts} = this.props;
        const {hardwareAccounts, page} = this.state;
        let isDisabled = (this.state.loading || this.isValid() === false);
        let warning = "";

        if(this.state.hardware === "trezor"){
            warning = "Please make sure your Trezor is connected before clicking 'Continue' below.";
        }
        else if(this.state.hardware === "ledger"){
            warning = "Please make sure your Ledger is connected with the Ethereum app open before clicking 'Continue' below.";
        }

        if(hardwareAccounts.length > 0 && this.state.warning.length === 0){
            return (
                <div className="UnlockWalletViaColdWallet">
                    <div className="UnlockWalletViaColdWallet__title">
                        Choose account
                    </div>
                    <HardwareAccountsList hardwareAccounts={hardwareAccounts.slice()}
                                          accounts={Object.assign({}, accounts)}
                                          onClick={this.onChooseHardwareAccount}
                    />

                    <div className="UnlockWalletViaColdWallet__footer">
                        <a onClick={this.goToPrevPage}>{'< Prev'}</a>
                        <div style={{flex: 1}}/>
                        <a onClick={this.goToNextPage}>{'Next >'}</a>
                    </div>
                </div>
            );
        }

        const divStyle = {
            "padding": "5px 0px 5px",
            "color": "#ED1E5C"
        }
        const divStyle2 = {
            "padding": "5px 0px 5px",
            "text-align": "left"
        }

        return (
            <div className="UnlockWalletViaMnemonicPhrase">
                
                {!this.state.validation &&  (<div className="UnlockWalletViaMnemonicPhrase__title">
                    <strong>Safe code</strong>
                </div>)}
                {!this.state.validation &&  (<input className="UnlockWalletViaMnemonicPhrase__password-input"
                    placeholder="Enter your Safe code here"
                    name="invcode"
                    type="text"
                    value={this.state.invcode}
                    ref={this.invcodeInput}
                    onChange={this.handleChange}
                />)}
                {!this.state.validation && (<div className="UnlockWalletPage__subtitle" style={divStyle2}>
                    Each <b>Safe code</b> is unique and specially generated for your e-mail. To participate, enter the code from your e-mail in the Safe code field above and press the 'Validate' button. Ensure you have at least 1 TFUEL in your wallet to cover the transaction fee. This code is your only key to accessing safety staking rewards.
                </div>)}
                {(!this.state.validation && this.state.validation !== null) &&  (<div className="UnlockWalletPage__subtitle" style={divStyle}>
                    Invalid safe code. Please try again with a valid code.
                </div>)}
                {!this.state.validation &&  (<div className="UnlockWalletViaColdWallet__footer">
                    <GradientButton title="Validate"
                                    loading={this.state.loading}
                                    onClick={this.checkInvitation}
                                    disabled={this.state.loading || this.state.invcode.length === 0 || !this.state.invcode.match(/[0-9a-fA-F]/)}
                    />
                </div>)}

                {this.state.validation && (<div className="UnlockWalletViaColdWallet__title">
                    Choose hardware
                </div>)}

                {(this.state.validation && this.state.warning.length > 0) && (<div className="UnlockWalletPage__subtitle" style={divStyle}>
                    {this.state.warning}
                </div>)}

                {this.state.validation && (<div className="UnlockWalletViaColdWallet__cold-wallet-hardware-select">
                    <HardwareOptionButton title="Trezor"
                                          iconUrl={(this.state.hardware === "trezor" ? "/img/icons/checkmark-green@2x.png" : null)}
                                          isSelected={(this.state.hardware === "trezor")}
                                          onClick={this.handleTrezorClick}
                    />
                    <HardwareOptionButton title="Ledger"
                                          iconUrl={(this.state.hardware === "ledger" ? "/img/icons/checkmark-green@2x.png" : null)}
                                          isSelected={(this.state.hardware === "ledger")}
                                          onClick={this.handleLedgerClick}
                    />
                </div>)}

                {(this.state.validation && this.state.warning.length === 0) && (<div className="UnlockWalletCard__warning" style={divStyle}>
                    {warning}
                </div>)}

                {this.state.validation && (<div className="UnlockColdWalletLedger__choose-derivation-path">
                    {
                        (this.state.hardware === "ledger") &&
                        <select value={this.state.derivationPath}
                                onChange={this.handleDerivationPathChange}
                                className={"UnlockColdWalletLedger__select"}
                        >
                            <option value={EthereumDerivationPath}>Ethereum - m/44'/60'/0'/0</option>
                            <option value={EthereumOtherDerivationPath}>Ethereum - m/44'/60'/0'</option>
                            <option value={EthereumLedgerLiveDerivationPath}>Ethereum - Ledger Live - m/44'/60'</option>
                        </select>
                    }
                </div>)}

                {this.state.validation && (<br/>)}

                {this.state.validation && (<div className="UnlockWalletViaColdWallet__footer">
                    <GradientButton title="Connect"
                                    loading={this.state.loading}
                                    onClick={this.handleChooseHardwareClick}
                                    disabled={isDisabled}
                    />
                </div>)}
            </div>
        );
    }
}
const UnlockWalletViaColdWalletStateToProps = state => {
    const {thetaWallet} = state;

    return {
        accounts: thetaWallet.accounts
    };
};
const UnlockWalletViaColdWallet = connect(UnlockWalletViaColdWalletStateToProps)(UnlockWalletViaColdWalletUnconnected);


class UnlockWalletCard extends React.Component {
    render() {
        let unlockWalletStrategyContent = null;

        if(this.props.unlockStrategy === WalletUnlockStrategy.KEYSTORE_FILE){
            unlockWalletStrategyContent = (
                <UnlockWalletViaKeystoreFile unlockWallet={this.props.unlockWallet}/>
            );
        }
        else if(this.props.unlockStrategy === WalletUnlockStrategy.MNEMONIC_PHRASE){
            unlockWalletStrategyContent = (
                <UnlockWalletViaMnemonicPhrase unlockWallet={this.props.unlockWallet}/>
            );
        }
        else if(this.props.unlockStrategy === WalletUnlockStrategy.PRIVATE_KEY){
            unlockWalletStrategyContent = (
                <UnlockWalletViaPrivateKey unlockWallet={this.props.unlockWallet}/>
            );
        }
        else if(this.props.unlockStrategy === WalletUnlockStrategy.COLD_WALLET){
            unlockWalletStrategyContent = (
                <UnlockWalletViaColdWallet/>
            );
        }

        return (
            <div className={classNames({UnlockWalletCard: true, [`UnlockWalletCard--${'mnemonic-phrase'/*this.props.unlockStrategy*/}`]: true})}>
                <div className="UnlockWalletCard__content">
                    <div className="UnlockWalletCard__header">
                        <TabBar centered={true}
                                condensed={true}
                                className="UnlockWalletCard__tab-bar">
                            <TabBarItem
                                title="Keystore"
                                href={"/unlock/" + WalletUnlockStrategy.KEYSTORE_FILE}
                            />
                            <TabBarItem
                                title="Mnemonic"
                                href={"/unlock/" + WalletUnlockStrategy.MNEMONIC_PHRASE}
                            />
                            <TabBarItem
                                title="Private Key"
                                href={"/unlock/" + WalletUnlockStrategy.PRIVATE_KEY}
                            />
                            <TabBarItem
                                title="Hardware"
                                href={"/unlock/" + WalletUnlockStrategy.COLD_WALLET}
                            />
                        </TabBar>
                    </div>

                    {unlockWalletStrategyContent}
                </div>
            </div>
        );
    }
}

export class UnlockWalletPage extends React.Component {
    constructor(){
        super();
        const ckie = getCookie('IC_2f132893b4a77211');
        if (ckie && ckie.length === 10 && ckie.match(/[0-9a-fA-F]{10}/)) {
            globalValidation = true;
        }
    }

    unlockWallet = (strategy, password, data) => {
        this.props.dispatch(unlockWallet(strategy, password, data));
    }

    componentDidMount() {
        let address = Wallet.getWalletAddress();

        if(!_.isNil(address)){
            // Incase the user went backwards after unlocking, reload the app
            window.location.reload();
        }
    }

    render() {
        let unlockStrategy = this.props.match.params.unlockStrategy;

        return (
            <div className="UnlockWalletPage">
                <div className="UnlockWalletPage__wrapper">
                    {/*<div className="UnlockWalletPage__title">*/}
                    {/*    Unlock Your Wallet*/}
                    {/*</div>*/}

                    <UnlockWalletCard unlockStrategy={unlockStrategy}
                                      unlockWallet={this.unlockWallet}
                    />

                    <div className="UnlockWalletPage__subtitle">
                        <span>Don't have a wallet?</span>
                        <Link to="/create">Create Wallet</Link>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {};
};

export default connect(mapStateToProps)(UnlockWalletPage);
